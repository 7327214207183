import React, { useState } from "react"
import { CardBody, Container, Row, Col, Card } from "reactstrap"
import { useHistory } from "react-router-dom"

import manifestIcon from "../../../assets/images/icons/manifest-icon.svg"
import eyeIcon from "assets/images/inventory-request/action-eye-icon.png"
import { SearchBox } from "components/SearchBox"
import { Pagination } from "components/Pagination"
import { DataTable } from "components/DataTable"
import { PageLimit } from "components/PageLimit"
import { GetPurchaseOrdersListing } from "../../../api/api.service"
import { useLocalPaginatedRequest } from "hooks/useLocalPaginatedRequest"
import { RequestStatus } from "../../../constants"
import { dateTimeFormat, convertDateTime } from "../../../utils/dateFormatUtils"
import { AutoComplete } from "components/AutoComplete"
import { Refresh } from "components/Refresh"
import { APP_ROUTES, getRoute } from "helpers/routeHelpers"
import MediaDetails from "./MediaDetails"
import customStyles from "../SelectStyles"
import { convertToSelectOptions } from "utils/commonUtils"

const COLUMNS = {
    ORDER_NO: "Order No.",
    PO_NO: "PO No.",
    ORDER_DATE: "Order Date",
    NO_OF_ITEMS: "Items",
    INVOICES: "Invoices",
    STATUS: "Status",
    ACTION: "Action",
    SHIPPING_MANIFEST: "Manifests",
}

const dataKey = "data"
const totalKey = "total"

const MEDIAS = {
    INVOICES: "INVOICES",
    MANIFESTS: "MANIFESTS",
}

const PurchaseOrdersListing = ({}) => {
    const history = useHistory()

    const [statusFilters, setStatusFilters] = useState(null)
    const [mediaContentToDownload, setMediaContentToDownload] = useState([])
    const [mediaContentRecord, setMediaContentRecord] = useState([])
    const [mediaEntityName, setMediaEntityName] = useState("")

    const {
        data,
        page,
        pageSize,
        isFetching,
        searchText,
        total,
        handleSearchChange,
        handlePageClick,
        onChangePageSize,
        request,
    } = useLocalPaginatedRequest({
        requestFn: GetPurchaseOrdersListing,
        params: {
            ...(statusFilters?.value && {
                requestStatus: statusFilters?.value,
            }),
        },
        deps: [statusFilters],
        dataKey: dataKey,
        totalKey: totalKey,
    })

    const handleSelectStatusOption = option => {
        setStatusFilters(option)
    }

    const handleNavigateToPurchaseOrderItems = data =>
        history.push(
            getRoute(APP_ROUTES.REQUEST_MANAGEMENT_POS_ITEMS, {
                poId: data.id,
            })
        )

    const handleModalClose = () => {
        setMediaContentToDownload([])
        setMediaContentRecord([])
    }

    const getMediaDetailsModalSubtitle = data => {
        return mediaEntityName == MEDIAS.MANIFESTS
            ? ``
            : `PO NUMBER: ${mediaContentRecord?.poNo}`
    }

    const handlePlaceMediaToDownload = (name, file) => {
        setMediaEntityName(name)
        setMediaContentToDownload(file)
    }

    return (
        <React.Fragment>
            <div className="gt-business-accounts">
                <Container fluid>
                    <Row>
                        <Col className="col-12 px-2 order-management-con">
                            <Card className="mb-5">
                                <Refresh
                                    onClick={request}
                                    isDisable={isFetching}
                                />
                                <CardBody className="p-2 p-md-4">
                                    <div className="d-flex align-items-center justify-content-between">
                                        <PageLimit
                                            pageSize={pageSize}
                                            changePageSizeHandler={
                                                onChangePageSize
                                            }
                                            rowLimit={pageSize}
                                            typoClasses="page-limit-typo"
                                            selectClasses="page-limit-select"
                                        />
                                        <div className="d-flex flex-wrap align-items-center gap-2">
                                            <div
                                            // style={{ width: "200px" }}
                                            // className="me-2"
                                            >
                                                <AutoComplete
                                                    customStyles={
                                                        customStyles.WarehouseIM
                                                    }
                                                    value={statusFilters}
                                                    isClearable
                                                    onChange={val =>
                                                        handleSelectStatusOption(
                                                            val
                                                        )
                                                    }
                                                    isSearchable={false}
                                                    placeholder="Select status"
                                                    classNamePrefix="status-header-search"
                                                    options={convertToSelectOptions(
                                                        RequestStatus
                                                    )}
                                                    // className="w-100 order-man-select"
                                                    filterOption={option =>
                                                        option.value !==
                                                        RequestStatus.PENDING
                                                    }
                                                />
                                            </div>
                                            <SearchBox
                                                isHideLable
                                                searchText={searchText}
                                                onChange={handleSearchChange}
                                                inputClasses="table-main-search-input wh-im-search-input"
                                                placeholder="Search..."
                                            />
                                        </div>
                                    </div>
                                    <DataTable
                                        data={data}
                                        loading={isFetching}
                                        config={[
                                            {
                                                title: COLUMNS.ORDER_NO,
                                                render: data => data.id,
                                            },
                                            {
                                                title: COLUMNS.PO_NO,
                                                render: data => data.poNo,
                                            },
                                            {
                                                title: COLUMNS.ORDER_DATE,
                                                render: data =>
                                                    convertDateTime({
                                                        date: data.createdAt,
                                                        customFormat:
                                                            dateTimeFormat.casitaCustomDateFormat,
                                                        dateOnly: true,
                                                    }),
                                            },

                                            {
                                                title: COLUMNS.NO_OF_ITEMS,
                                                render: data =>
                                                    data.orders.length,
                                            },

                                            {
                                                title: COLUMNS.STATUS,
                                                render: data => {
                                                    return (
                                                        <div
                                                            className={`inv-req-popup-status ${RequestStatus.getLabelClass(
                                                                data.requestStatus
                                                            )}`}
                                                        >
                                                            {RequestStatus.getDisplayTextKey(
                                                                data.requestStatus
                                                            )}
                                                        </div>
                                                    )
                                                },
                                            },

                                            {
                                                title: COLUMNS.INVOICES,
                                                render: data => {
                                                    return data?.invoices
                                                        ?.length ? (
                                                        <div
                                                            className="pointer"
                                                            onClick={() => {
                                                                setMediaContentRecord(
                                                                    data
                                                                )
                                                                return handlePlaceMediaToDownload(
                                                                    MEDIAS.INVOICES,
                                                                    data.invoices
                                                                )
                                                            }}
                                                        >
                                                            <img
                                                                src={
                                                                    manifestIcon
                                                                }
                                                            />
                                                        </div>
                                                    ) : (
                                                        "-"
                                                    )
                                                },
                                            },

                                            {
                                                title: COLUMNS.SHIPPING_MANIFEST,
                                                render: data => {
                                                    return data
                                                        ?.purchaseOrderItemReceiving
                                                        ?.length ? (
                                                        <div
                                                            className="pointer"
                                                            onClick={() => {
                                                                let mediaArr =
                                                                    data.purchaseOrderItemReceiving.map(
                                                                        item => ({
                                                                            ...item.purchaseOrderManifest,
                                                                            imageTitle: `REQUEST ID: ${item.warehouseRequestItem.warehouseRequestId}`,
                                                                        })
                                                                    )
                                                                setMediaContentRecord(
                                                                    data
                                                                )

                                                                return handlePlaceMediaToDownload(
                                                                    MEDIAS.MANIFESTS,
                                                                    mediaArr
                                                                )
                                                            }}
                                                        >
                                                            <img
                                                                src={
                                                                    manifestIcon
                                                                }
                                                            />
                                                        </div>
                                                    ) : (
                                                        "-"
                                                    )
                                                },
                                            },

                                            {
                                                className: "text-center",
                                                title: COLUMNS.ACTION,
                                                render: data => {
                                                    return (
                                                        <span
                                                            className="text-success pointer"
                                                            onClick={() => {
                                                                handleNavigateToPurchaseOrderItems(
                                                                    data
                                                                )
                                                            }}
                                                        >
                                                            <img
                                                                src={eyeIcon}
                                                                className="action-icons-size"
                                                            />
                                                        </span>
                                                    )
                                                },
                                            },
                                        ]}
                                    />
                                    <Pagination
                                        currentPage={page}
                                        totalData={total}
                                        onPageClick={handlePageClick}
                                        rowLimit={pageSize}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
            <MediaDetails
                subTitle={getMediaDetailsModalSubtitle()}
                title={mediaEntityName}
                mediaArr={mediaContentToDownload}
                isOpen={mediaContentToDownload?.length}
                onToggle={handleModalClose}
            />
        </React.Fragment>
    )
}

export default PurchaseOrdersListing
