import React from "react"
import moment from "moment"
import useDownloader from "react-use-downloader"

import { Modal } from "../../../components/Modal"
import manifestIcon from "../../../assets/images/icons/manifest-icon.svg"
import { Button } from "../../../components/Button"

const DATE_TODAY = moment().format("MMMM-DD-YYYY")

const MediaDetails = ({
    isOpen,
    onToggle,
    mediaArr = [],
    title = "Title...",
    subTitle = "Sub Title..",
    imageTitle = "",
}) => {
    const { download } = useDownloader()

    return (
        <Modal
            isOpen={isOpen}
            handleModalToggling={onToggle}
            bodyClassName="p-0"
            customButton
            hideModalHeaderSeparator
            headerClasses="pb-0"
            isScrollable
        >
            <h3 className="text-center inv-req-popup-heading fs-20">{title}</h3>
            <h5 className="text-center ">{subTitle}</h5>

            <div className="media-container d-flex flex-wrap justify-content-center">
                {mediaArr.map((mediaItem, idx) => {
                    let filename = `${DATE_TODAY}.${mediaItem.media.extension}`
                    return (
                        <div
                            onClick={() =>
                                download(mediaItem.media.path, filename)
                            }
                            key={idx}
                            className="media-item d-flex flex-column justify-content-center align-items-center m-2 pointer"
                        >
                            <div className="media-date text-black">
                                {imageTitle || mediaItem.imageTitle}
                            </div>
                            <div className="media-icon-box w-100 h-100 d-flex mb-2 justify-content-center align-items-center">
                                <img
                                    src={manifestIcon}
                                    className="media-icon"
                                />
                            </div>
                            <div className="media-date text-black">
                                {moment(mediaItem.media.createdAt).format(
                                    "MMMM DD,YYYY"
                                )}
                            </div>
                        </div>
                    )
                })}
            </div>
            <div className="text-center my-3">
                <Button
                    className="gt-btn-grad-primary mx-2 "
                    title={"Done"}
                    onClick={onToggle}
                />
            </div>
        </Modal>
    )
}

export default MediaDetails
