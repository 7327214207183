import React, { useState, useEffect } from "react"
import { CardBody, Card } from "reactstrap"
import { toast } from "react-hot-toast"

import customStyles from "./HeaderStatusStyling"
import ActionEyeIcon from "assets/images/inventory-request/action-eye-icon.png"
import { Pagination } from "components/Pagination"
import { DataTable } from "components/DataTable"
import { PageLimit } from "components/PageLimit"
import { AutoComplete } from "components/AutoComplete"
import { Modal } from "components/Modal"
import { useLocalPaginatedRequest } from "hooks/useLocalPaginatedRequest"
import { dateTimeFormat, convertDateTime } from "utils/dateFormatUtils"
import {
    GetScrapReturnListing,
    GetScrapReturnRequestById,
    GetDepartmentsListing,
    WarehouseInventoryListing,
    CreateScrapReturnRequest,
} from "api/api.service"
import { getCommaSeparatedStrings, truncateString } from "utils/commonUtils"
import Config from "Config"
import { Refresh } from "components/Refresh"
import { UserTypes } from "constants"
import TheftForm from "pages/Common/Theft/TheftForm"

const COLUMNS = {
    SKU_NO: "SKU No.",
    ITEM_NAME: "Item Name",
    STATION: "Station Name",
    TYPE: "Type",
    QUANTITY: "Quantity",
    DATE: "Date",
    ACTION: "Action",
}

const DATA_KEY = "data"
const TOTAL_KEY = "total"
const SCRAP_REQUEST = "DEPARTMENT"

const InventoryReqDepartmentTheftListing = ({}) => {
    let controller = new AbortController()
    let signal = controller.signal

    const [isFetchingRequest, setIsFetchingRequest] = useState(false)
    const [isSubmittingScrap, setIsSubmittingScrap] = useState(false)
    const [toggleModal, setToggleModal] = useState(false)
    const [isReadOnly, setIsReadOnly] = useState(false)
    const [selectedListItem, setSelectedListItem] = useState("")
    const [selectedDepartment, setSelectedDepartment] = useState(null)
    const [departmentFilter, setDepartmentFilter] = useState({
        type: "",
        id: "",
    })
    const [departmentsListing, setDepartmentsListing] = useState({
        data: [],
        fetching: true,
        page: 1,
        isDataAvailable: true,
    })

    const {
        page,
        data,
        total,
        pageSize,
        isFetching,
        searchText,
        request,
        handleSearchChange,
        handlePageClick,
        onChangePageSize,
    } = useLocalPaginatedRequest({
        requestFn: GetScrapReturnListing,
        params: {
            ...{ type: UserTypes.DEPARTMENT },
            requestType: "Theft",
            ...(departmentFilter?.type &&
                departmentFilter.id && {
                    id: departmentFilter?.id,
                    type: departmentFilter?.type,
                }),
        },
        deps: [departmentFilter],
        dataKey: DATA_KEY,
        totalKey: TOTAL_KEY,
    })

    useEffect(() => {
        handleDepartmentsListing(departmentsListing.page)

        return () => {
            controller.abort()
        }
    }, [departmentsListing.Q])

    const handleSelectDepartments = option => {
        if (typeof option != "object" || !option) return
        setSelectedDepartment(option)
        let arr = option.map(item => item.id)
        let commaSeparatedStrs = getCommaSeparatedStrings(arr)
        setDepartmentFilter({
            type: SCRAP_REQUEST,
            id: commaSeparatedStrs,
        })
    }

    const handleSearch = (value = "", callback) => {
        if (!value) return

        callback(prev => {
            return {
                ...prev,
                data: [],
                page: 1,
                Q: value,
            }
        })
    }

    const handleDepartmentsListing = page => {
        setDepartmentsListing(prev => {
            return {
                ...prev,
                fetching: true,
            }
        })

        GetDepartmentsListing(
            {
                Limit: Config.LIMIT,
                ...(departmentsListing.Q && { Q: departmentsListing.Q }),
                Page: page,
            },
            signal
        )
            .then(res => {
                setDepartmentsListing(prev => ({
                    ...prev,
                    fetching: false,
                    page: page + 1,
                    isDataAvailable: res?.data?.length == Config.LIMIT,
                    data: (() => {
                        let test = res?.data?.map(item => ({
                            ...item,
                            value: item.id,
                            label: item.departmentName,
                        }))

                        return [...test]
                    })(),
                }))
            })
            .finally(() => {
                setDepartmentsListing(prev => {
                    return {
                        ...prev,
                        fetching: false,
                    }
                })
            })
    }

    const handleViewScrapDetails = async (scrapId = null) => {
        if (!scrapId) return
        setIsReadOnly(true)

        let paramsObj = {
            type: UserTypes.DEPARTMENT,
        }

        setToggleModal(true)

        try {
            setIsFetchingRequest(true)
            let res = await GetScrapReturnRequestById(scrapId, paramsObj)
            setSelectedListItem(res)
        } catch (err) {
            // toast.error(err.message)
        } finally {
            setIsFetchingRequest(false)
        }
    }

    const handleSubmitScrap = async payload => {
        try {
            setIsSubmittingScrap(true)
            let res = await CreateScrapReturnRequest(payload)
            toast.success("Request has been created successfully !")
            request()
        } catch (err) {
            // toast.error(err.message)
        } finally {
            setIsSubmittingScrap(false)
            setToggleModal(!toggleModal)
        }
    }

    return (
        <>
            <Card className="mb-5">
                <Refresh onClick={request} isDisable={isFetching} />
                <CardBody className="p-2 p-md-4 wh-im-wh-gi-con">
                    <div className="d-flex align-items-start align-items-md-center justify-content-between flex-column flex-md-row mb-3 mb-md-0">
                        <PageLimit
                            pageSize={pageSize}
                            changePageSizeHandler={onChangePageSize}
                            rowLimit={pageSize}
                            typoClasses="page-limit-typo"
                            selectClasses="page-limit-select"
                            mainClass="mt-0 mb-3 my-md-3"
                        />
                        <div className="d-flex align-items-center justify-content-center  ">
                            <div className="status-search-con wh-im-dpgi-status me-2">
                                <AutoComplete
                                    isMulti
                                    placeholder="Stations"
                                    value={selectedDepartment}
                                    customStyles={customStyles.IMRequests}
                                    classNamePrefix="status-header-search"
                                    handleBlur={() =>
                                        setDepartmentsListing(prev => {
                                            return {
                                                ...prev,
                                                page: 1,
                                                Q: "",
                                            }
                                        })
                                    }
                                    onInputChange={val =>
                                        handleSearch(val, setDepartmentsListing)
                                    }
                                    options={departmentsListing.data}
                                    onChange={val =>
                                        handleSelectDepartments(val)
                                    }
                                    closeMenuOnSelect={true}
                                    isLoading={departmentsListing?.fetching}
                                />
                            </div>
                        </div>
                    </div>
                    <DataTable
                        data={data}
                        loading={isFetching}
                        tableClasses="inv-req-scrapreturn-table"
                        config={[
                            {
                                title: COLUMNS.SKU_NO,
                                render: data => {
                                    return (
                                        <>
                                            <div
                                                onClick={() =>
                                                    setInventoryDetailsModal(
                                                        true
                                                    )
                                                }
                                            >
                                                {data.skuNo || "-"}
                                            </div>
                                        </>
                                    )
                                },
                            },
                            {
                                title: COLUMNS.ITEM_NAME,
                                render: data => {
                                    return data?.itemName || "-"
                                },
                            },
                            {
                                title: COLUMNS.STATION,
                                render: data => {
                                    return (
                                        <>
                                            {data?.department?.departmentName ||
                                                "-"}
                                        </>
                                    )
                                },
                            },

                            {
                                title: COLUMNS.QUANTITY,
                                render: data => {
                                    return <>{data.quantity || "-"}</>
                                },
                            },
                            {
                                title: COLUMNS.DATE,
                                render: data => {
                                    return (
                                        convertDateTime({
                                            date: data?.theftDate,
                                            customFormat:
                                                dateTimeFormat.casitaCustomDateFormatOnly,
                                            dateOnly: true,
                                        }) || "-"
                                    )
                                },
                            },
                            {
                                className: "text-center",
                                title: COLUMNS.ACTION,
                                render: data => {
                                    return (
                                        <span
                                            className="text-success pointer"
                                            onClick={() =>
                                                handleViewScrapDetails(data.id)
                                            }
                                        >
                                            <img
                                                src={ActionEyeIcon}
                                                className="action-icons-size"
                                            />
                                        </span>
                                    )
                                },
                            },
                        ]}
                    />
                    <Pagination
                        currentPage={page}
                        totalData={total}
                        onPageClick={handlePageClick}
                        rowLimit={pageSize}
                    />
                </CardBody>
            </Card>
            <Modal
                isOpen={toggleModal}
                handleModalToggling={() => setToggleModal(false)}
                sizeClasses="10"
            >
                <TheftForm
                    quantityKey="quantity"
                    data={selectedListItem}
                    closeModal={() => setToggleModal(false)}
                    isReadOnly={isReadOnly}
                    isSubmitting={isSubmittingScrap}
                    apiFunction={WarehouseInventoryListing}
                    onSubmit={handleSubmitScrap}
                />
            </Modal>
        </>
    )
}

export default InventoryReqDepartmentTheftListing
