import React, { useState } from "react"
import { useHistory } from "react-router-dom"
import { Col, Container, Row, Card, CardBody } from "reactstrap"

import TheftIcon from "assets/images/icons/theft-icon.png"
import ActionEyeIcon from "assets/images/inventory-request/action-eye-icon.png"
import { BackButtonTableTop } from "components/BackButtonTableTop"
import { TableTopHeading } from "components/TableTopHeading"
import { PageLimit } from "components/PageLimit"
import { DataTable } from "components/DataTable"
import { Pagination } from "components/Pagination"
import { Button } from "components/Button"
import { Modal } from "components/Modal"
import { useLocalPaginatedRequest } from "hooks/useLocalPaginatedRequest"
import { convertDateTime, dateTimeFormat } from "utils/dateFormatUtils"
import {
    GetScrapReturnListing,
    GetScrapReturnRequestById,
} from "api/api.service"
import { Refresh } from "components/Refresh"
import useDepartmentConfig from "hooks/useDepartmentConfig"
import { UserTypes } from "../../../constants"
import TheftForm from "pages/Common/Theft/TheftForm"
import { truncateString } from "utils/commonUtils"

const COLUMNS = {
    SKU_NO: "SKU No.",
    ITEM_NAME: "Item Name",
    TYPE: "Type",
    QUANTITY: "Quantity",
    STATION: "Station",
    DATE: "Date",
    ACTION: "Action",
}

const DATA_KEY = "data"
const TOTAL_KEY = "total"

const TheftListing = ({}) => {
    const {
        currentDepartmentId,
        departmentIds,
        currentDepartmentName,
        departmentManagerName,
    } = useDepartmentConfig()

    const history = useHistory()
    const [toggleModal, setToggleModal] = useState(false)
    const [isFetchingRequest, setIsFetchingRequest] = useState(false)
    const [selectedListItem, setSelectedListItem] = useState("")

    const {
        page,
        data,
        total,
        pageSize,
        isFetching,
        request,
        handlePageClick,
        onChangePageSize,
    } = useLocalPaginatedRequest({
        requestFn: GetScrapReturnListing,
        params: {
            ...(currentDepartmentId
                ? { departmentId: [currentDepartmentId] }
                : { departmentId: departmentIds }),
            requestType: "Theft",
        },
        deps: [currentDepartmentId],
        dataKey: DATA_KEY,
        totalKey: TOTAL_KEY,
    })

    const handleViewTheftDetails = async (scrapId = null) => {
        if (!scrapId) return

        let paramsObj = {
            type: UserTypes.DEPARTMENT,
        }

        setToggleModal(true)

        try {
            setIsFetchingRequest(true)
            let res = await GetScrapReturnRequestById(scrapId, paramsObj)
            setSelectedListItem(res)
        } catch (err) {
        } finally {
            setIsFetchingRequest(false)
        }
    }

    const onCloseModal = () => {
        setToggleModal(false)
        setSelectedListItem("")
    }

    return (
        <>
            <div className="gt-business-accounts px-3 px-md-5">
                <Container fluid>
                    <Row>
                        <Col className="col-12 ">
                            <div className="d-flex align-items-center mb-4">
                                <BackButtonTableTop />
                            </div>

                            <TableTopHeading
                                title={`Theft (${
                                    currentDepartmentId
                                        ? currentDepartmentName
                                        : departmentManagerName
                                })`}
                                iconImage={TheftIcon}
                                iconClass="scrap-icon"
                            />

                            <Card className="mb-5">
                                <Refresh
                                    onClick={request}
                                    isDisable={isFetching}
                                />
                                <CardBody className="p-2 p-md-4">
                                    <PageLimit
                                        pageSize={pageSize}
                                        changePageSizeHandler={onChangePageSize}
                                        rowLimit={pageSize}
                                        typoClasses="page-limit-typo"
                                        selectClasses="page-limit-select"
                                    />

                                    <DataTable
                                        data={data}
                                        loading={isFetching}
                                        tableClasses="table-listing"
                                        config={[
                                            {
                                                title: COLUMNS.SKU_NO,
                                                render: data => {
                                                    return (
                                                        <>
                                                            <div
                                                                onClick={() =>
                                                                    setInventoryDetailsModal(
                                                                        true
                                                                    )
                                                                }
                                                            >
                                                                {data.skuNo ||
                                                                    "-"}
                                                            </div>
                                                        </>
                                                    )
                                                },
                                            },
                                            {
                                                title: COLUMNS.ITEM_NAME,
                                                render: data => {
                                                    return data?.itemName || "-"
                                                },
                                            },

                                            {
                                                title: COLUMNS.QUANTITY,
                                                render: data => {
                                                    return (
                                                        <>
                                                            {data.quantity ||
                                                                "-"}
                                                        </>
                                                    )
                                                },
                                            },
                                            {
                                                title: COLUMNS.STATION,
                                                render: data => {
                                                    return (
                                                        <>
                                                            {data?.department
                                                                ?.departmentName ||
                                                                "-"}
                                                        </>
                                                    )
                                                },
                                            },
                                            {
                                                title: COLUMNS.DATE,
                                                render: data => {
                                                    return (
                                                        convertDateTime({
                                                            date: data.theftDate,
                                                            customFormat:
                                                                dateTimeFormat.casitaCustomDateFormat,
                                                            dateOnly: true,
                                                        }) || "-"
                                                    )
                                                },
                                            },
                                            {
                                                className: "text-center",
                                                title: COLUMNS.ACTION,
                                                render: data => {
                                                    return (
                                                        <span
                                                            className="text-success pointer"
                                                            onClick={() =>
                                                                handleViewTheftDetails(
                                                                    data.id
                                                                )
                                                            }
                                                        >
                                                            <img
                                                                src={
                                                                    ActionEyeIcon
                                                                }
                                                                className="action-icons-size"
                                                            />
                                                        </span>
                                                    )
                                                },
                                            },
                                        ]}
                                    />
                                    <Pagination
                                        currentPage={page}
                                        totalData={total}
                                        onPageClick={handlePageClick}
                                        rowLimit={pageSize}
                                    />
                                </CardBody>
                            </Card>
                            <div className="text-center ">
                                <Button
                                    size="lg"
                                    className="gt-btn-grad-primary table-bottom-btn btn-alignment mb-3"
                                    title={<div>Back to Main</div>}
                                    onClick={history.goBack}
                                />
                            </div>
                        </Col>
                    </Row>

                    {toggleModal && (
                        <Modal
                            isOpen={toggleModal}
                            handleModalToggling={() => setToggleModal(false)}
                            sizeClasses="5"
                        >
                            <TheftForm
                                data={selectedListItem}
                                closeModal={() => onCloseModal()}
                            />
                        </Modal>
                    )}
                </Container>
            </div>
        </>
    )
}

export default TheftListing
