import React, { useState } from "react"
import { CardBody, Container, Row, Col, Card } from "reactstrap"
import { useParams } from "react-router-dom"

import { TableTopHeading } from "components/TableTopHeading"
import { BackButtonTableTop } from "components/BackButtonTableTop"
import { SearchBox } from "components/SearchBox"
import { Pagination } from "components/Pagination"
import { DataTable } from "components/DataTable"
import { PageLimit } from "components/PageLimit"
import { GetWarehouseToFinanceRequestsListingItemsReceiving } from "../../../api/api.service"
import { useLocalPaginatedRequest } from "hooks/useLocalPaginatedRequest"
import { dateTimeFormat, convertDateTime } from "../../../utils/dateFormatUtils"
import { getAppName } from "utils/commonUtils"
import { Refresh } from "components/Refresh"
import manifestIcon from "../../../assets/images/icons/manifest-icon.svg"
import InventoryRequestIcon from "assets/images/warehouse/inventory-request/wh-rm-header-icon.png"
import MediaDetails from "./MediaDetails"

const COLUMNS = {
    PO_NO: "PO No.",
    VENDOR_NAME: "Vendor Name",
    DATE_RECEIVED: "Date Received",
    RECEIVED_QUANTITY: "Received Qty",
    UNIT_PRICE: "Price",
    INVOICE: "Invoices",
    MANIFEST: "Manifests",
}

const dataKey = "data"
const totalKey = "total"

const MEDIAS = {
    INVOICES: "INVOICES",
    MANIFESTS: "MANIFESTS",
}

const RequestItemsReceivingListing = ({}) => {
    const { requestItemId } = useParams()
    const [mediaContentToDownload, setMediaContentToDownload] = useState([])
    const [mediaEntityName, setMediaEntityName] = useState("")

    const {
        data,
        page,
        pageSize,
        isFetching,
        searchText,
        total,
        handleSearchChange,
        handlePageClick,
        onChangePageSize,
        request,
    } = useLocalPaginatedRequest({
        requestFn: GetWarehouseToFinanceRequestsListingItemsReceiving,
        deps: [],
        dataKey: dataKey,
        totalKey: totalKey,
        id: requestItemId,
    })

    const handleModalClose = () => {
        setMediaContentToDownload([])
    }

    const handlePlaceMediaToDownload = (name, file) => {
        setMediaEntityName(name)
        setMediaContentToDownload(file)
    }

    const getMediaDetailsModalSubtitle = () => {
        return mediaEntityName == MEDIAS.MANIFESTS
            ? `REQUEST ID: ${data[0]?.purchaseOrderItem?.warehouseRequestId}`
            : `PO NUMBER: ${data[0]?.purchaseOrder?.poNo}`
    }

    return (
        <>
            <div className="gt-business-accounts">
                <Container fluid>
                    <Row>
                        <Col className="col-12 px-lg-3 px-2 order-management-con">
                            <div className="d-flex align-items-center mb-4 mb-4 mt-lg-0 mt-3">
                                <BackButtonTableTop />
                            </div>
                            <div className="d-flex align-items-center top-heading-con mb-2 order-man-top-heading">
                                <TableTopHeading
                                    title={`Request Management (${getAppName()})`}
                                    className="me-4 top-heading-typo"
                                    iconImage={InventoryRequestIcon}
                                    iconClass="inventory-req-icon"
                                />
                            </div>

                            <Card className="mb-5">
                                <Refresh
                                    onClick={request}
                                    isDisable={isFetching}
                                />
                                <CardBody className="p-2 p-md-4">
                                    <h5 className="fw-600 mx-2 pb-2">
                                        SKU#: {data[0]?.item.skuNo}
                                    </h5>
                                    <p className="mx-2 p-0">
                                        ITEM: {data[0]?.item.itemName}
                                    </p>
                                    {data[0]?.substitute?.id && (
                                        <p className="mx-2 p-0">
                                            SUBSTITUTE ITEM:{" "}
                                            {data[0]?.substitute?.itemName}
                                        </p>
                                    )}
                                    <div className="d-flex align-items-center justify-content-between">
                                        <PageLimit
                                            pageSize={pageSize}
                                            changePageSizeHandler={
                                                onChangePageSize
                                            }
                                            rowLimit={pageSize}
                                            typoClasses="page-limit-typo"
                                            selectClasses="page-limit-select"
                                        />
                                        {/* <div>
                                            <SearchBox
                                                searchText={searchText}
                                                onChange={handleSearchChange}
                                                inputClasses="table-main-search-input wh-im-search-input"
                                            />
                                        </div> */}
                                    </div>
                                    <DataTable
                                        data={data}
                                        loading={isFetching}
                                        config={[
                                            {
                                                title: COLUMNS.PO_NO,
                                                render: data => (
                                                    <span>
                                                        {
                                                            data.purchaseOrder
                                                                .poNo
                                                        }
                                                    </span>
                                                ),
                                            },
                                            {
                                                title: COLUMNS.VENDOR_NAME,
                                                render: data => (
                                                    <span>
                                                        {
                                                            data.purchaseOrder
                                                                .vendorName
                                                        }
                                                    </span>
                                                ),
                                            },

                                            {
                                                title: COLUMNS.DATE_RECEIVED,
                                                render: data =>
                                                    convertDateTime({
                                                        date: data.createdAt,
                                                        customFormat:
                                                            dateTimeFormat.casitaCustomDateFormat,
                                                        dateOnly: true,
                                                    }),
                                            },
                                            {
                                                title: COLUMNS.UNIT_PRICE,
                                                render: data => (
                                                    <div>
                                                        {Number(
                                                            data
                                                                ?.purchaseOrderItem
                                                                ?.unitPrice
                                                        )?.toFixed(2) || "-"}
                                                    </div>
                                                ),
                                            },

                                            {
                                                title: COLUMNS.RECEIVED_QUANTITY,
                                                render: data => (
                                                    <span>
                                                        {data.quantitySupplied}
                                                    </span>
                                                ),
                                            },

                                            {
                                                title: COLUMNS.INVOICE,
                                                render: data => {
                                                    return data?.invoices
                                                        ?.length ? (
                                                        <div
                                                            className="pointer"
                                                            onClick={() =>
                                                                handlePlaceMediaToDownload(
                                                                    MEDIAS.INVOICES,
                                                                    data.invoices
                                                                )
                                                            }
                                                        >
                                                            <img
                                                                src={
                                                                    manifestIcon
                                                                }
                                                            />
                                                        </div>
                                                    ) : (
                                                        "-"
                                                    )
                                                },
                                            },

                                            {
                                                title: COLUMNS.MANIFEST,
                                                render: data => {
                                                    return data?.purchaseOrderManifest ? (
                                                        <div
                                                            className="pointer"
                                                            onClick={() =>
                                                                handlePlaceMediaToDownload(
                                                                    MEDIAS.MANIFESTS,
                                                                    [
                                                                        {
                                                                            ...data.purchaseOrderManifest,
                                                                        },
                                                                    ]
                                                                )
                                                            }
                                                        >
                                                            <img
                                                                src={
                                                                    manifestIcon
                                                                }
                                                            />
                                                        </div>
                                                    ) : (
                                                        "-"
                                                    )
                                                },
                                            },
                                        ]}
                                    />
                                    <Pagination
                                        currentPage={page}
                                        totalData={total}
                                        onPageClick={handlePageClick}
                                        rowLimit={pageSize}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>

            <MediaDetails
                subTitle={getMediaDetailsModalSubtitle()}
                title={mediaEntityName}
                mediaArr={mediaContentToDownload}
                isOpen={mediaContentToDownload?.length}
                onToggle={handleModalClose}
            />
        </>
    )
}

export default RequestItemsReceivingListing
